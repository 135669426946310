import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import get from 'lodash/get';

// eslint-disable-next-line react/prefer-stateless-function
export default class MCPCard extends Component {
  render() {
    const { image, name, company, url } = this.props;

    return (
      <div className="mcp__card">
        <img className="mcp__card__image" alt="mcp person" src={image} />
        <p className="mcp__card__name">{name}</p>
        <p
          className="mcp__card__company"
          dangerouslySetInnerHTML={{ __html: company }}
        />
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a target="_blank" href={url} />
      </div>
    );
  }
}

MCPCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};
