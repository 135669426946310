import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Social from '../../social/social';
import MCPDropdown from '../../mcp/mcp_dropdown/mcp_dropdown';
import MCPCatGrid from '../../mcp/mcp_category_grid/mcp_category_grid';
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
export default class MostCreativePeopleArticle extends Component {
  constructor(props) {
    super(props);
    const post = get(props.posts, '[0]', {});
    const mcpData = get(post, 'customFields.mcp_list', {});

    const categories = [];
    const allEntries = [];

    for (const property in mcpData) {
      if (property.includes('cat_entries_')) {
        categories.push(mcpData[property]);
        const entries = mcpData[property].entries;
        if (entries) {
          entries.forEach(entry => {
            //
            allEntries.push(entry);
          });
        }
      }
    }

    this.state = {
      categories,
      selectedCategory: 'The Full List',
      currColor: get(categories, '[0].color', ''),
      allEntries
    };

    this.navRef = React.createRef();
    this.navDivRef = React.createRef();

    this.updateSelectedCategory = this.updateSelectedCategory.bind(this);
    this.updateSelectedColor = this.updateSelectedColor.bind(this);
    this.callToggleDropdown = this.callToggleDropdown.bind(this);
  }

  componentDidMount() {}

  // componentDidUpdate() {
  //   console.log('I updated');
  // }

  updateSelectedCategory(category, color) {
    this.setState(
      {
        selectedCategory: category,
        currColor: color
      } // ,
      // console.log('newstate', this.state, category)
    );
    // console.log('called');
    this.forceUpdate();
  }

  updateSelectedColor(color) {
    this.setState({
      currColor: color
    });
    this.forceUpdate();
  }

  callToggleDropdown() {
    this.navDivRef.current.scrollIntoView({ behavior: 'smooth' });
    setTimeout(this.navRef.current.toggleDropdown, 200);
  }

  render() {
    const post = get(this.props.posts, '[0]', {});
    const content = get(post, 'content', []);
    // Clean up the entry data.
    const categories = get(this.state, 'categories', []);
    const selectedCategory = get(this.state, 'selectedCategory', '');
    let currCategory = {};
    let currColor = '';

    if (selectedCategory === 'The Full List') {
      currColor = get(this.state, 'currColor', '#ECE4CF');
    } else {
      categories.forEach(category => {
        if (category.display_name === selectedCategory) {
          currCategory = category;
          currColor = get(category, 'color', '#ECE4CF');
        }
      });
    }

    return (
      <div
        className="mcp"
        style={{
          backgroundColor: currColor,
          transition: 'background-color 250ms'
        }}
      >
        <div className="mcp__container">
          <Social
            enableSticky={true}
            empty={false}
            topValue={100}
            text={encodeURIComponent(get(post, 'title') || '')}
            uri={post.isNewUrl ? get(post, 'newUrl', '') : post.link}
            modifier="mcp-2020-desktop"
            bottomBoundary={`#social--${post.id}`}
            encapsulateId={`social--${post.id}`}
            active={this.state.social}
            overrideColor={currColor}
            {...this.props}
          />
          {/* Eventually make this come from the article */}
          <div>
            <div className="mcp__header">
              <img
                className="mcp__header__image mcp__header__image__mobile"
                alt="mcp mobile header"
                src="https://images.fastcompany.net/image/upload/v1595020387/fcweb/header-graphic-mobile_rpwz9d.svg"
              />
              <object
                className="mcp__header__image mcp__header__image__desktop"
                type="image/svg+xml"
                data="https://assets.fastcompany.com/asset_files/static/svg/fc-mcp2020-desktop-v4.svg"
                aria-label="mcp desktop animated"
              />
            </div>
            <h2 className="mcp__desc">
              {content.map(cont =>
                cont.map(text => (
                  <span dangerouslySetInnerHTML={{ __html: text }} />
                ))
              )}
            </h2>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a className="mcp__nav-waypoint" ref={this.navDivRef} />

            <Social
              enableSticky={false}
              empty={false}
              // topValue={fullPageAdvertorial ? 160 : 111}
              text={encodeURIComponent(get(post, 'title') || '')}
              uri={post.isNewUrl ? get(post, 'newUrl', '') : post.link}
              modifier="mcp-2020"
              active={this.state.social}
              overrideColor={currColor}
              {...this.props}
            />
            <div className="mcp__nav">
              <object
                className="mcp__forimage"
                type="image/svg+xml"
                data="https://images.inc.com/img/mcp-for-animated-2.svg"
                aria-label="mcp for animated"
              />
              {/* <img
                className="mcp__forimage"
                alt="mcp for gif"
                src="https://images.fastcompany.net/image/upload/v1593718135/fcweb/for-static_2x_py3wr7.png"
              /> */}
              {/* NAV TO GO HERE */}
              <MCPDropdown
                categories={categories}
                selectedCategory={this.state.selectedCategory}
                updateCategory={this.updateSelectedCategory}
                ref={this.navRef}
              />
            </div>

            {selectedCategory === 'The Full List' ? (
              categories.map(cat => (
                <MCPCatGrid
                  entries={get(cat, 'entries', [])}
                  color={get(cat, 'color', '')}
                  title={get(cat, 'display_name', '')}
                  updateColor={this.updateSelectedColor}
                />
              ))
            ) : (
              <MCPCatGrid entries={get(currCategory, 'entries', [])} />
            )}
          </div>
        </div>
        <div className="mcp__view-cat" onClick={this.callToggleDropdown}>
          <img
            src="https://images.fastcompany.net/image/upload/v1595022995/fcweb/lines-left_vthe4h.svg"
            alt="left line"
          />
          <p>VIEW CATEGORIES</p>
          <img
            src="https://images.fastcompany.net/image/upload/v1595022995/fcweb/lines_right_t4hia2.svg"
            alt="right line"
          />
        </div>
      </div>
    );
  }
}

MostCreativePeopleArticle.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      customFields: PropTypes.shape({
        list_article: PropTypes.shape({
          copy_display_style: PropTypes.string,
          open_first_accordion: PropTypes.bool
        })
      })
    })
  ).isRequired
};
