import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownItem from './dropdown_item';
import get from 'lodash/get';

export default class MCPDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDrop: false
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.listItemOnClick = this.listItemOnClick.bind(this);
  }

  toggleDropdown() {
    this.setState({
      showDrop: !this.state.showDrop
    });
  }

  listItemOnClick(category, color) {
    const updateCategory = get(this.props, 'updateCategory', () => {});
    this.toggleDropdown();
    updateCategory(category, color);
  }

  render() {
    const { categories, selectedCategory } = this.props;
    const showDrop = get(this.state, 'showDrop', false);

    return (
      <React.Fragment>
        <div
          className={`mcp-dropdown ${showDrop ? 'mcp-dropdown--active' : ''}`}
          onClick={this.toggleDropdown}
        >
          <div className="mcp-dropdown__selected">{selectedCategory}</div>

          <div
            className={`mcp-dropdown__list ${
              showDrop ? 'mcp-dropdown__list--active' : ''
            }`}
          >
            <DropdownItem
              category="The Full List"
              color="#ECE4CF"
              onClick={() => {
                // updateCategory(category.display_name);
                this.listItemOnClick(`The Full List`);
              }}
            />
            {categories.map(category => (
              <DropdownItem
                category={category.display_name}
                color={category.color}
                onClick={() => {
                  // updateCategory(category.display_name);
                  this.listItemOnClick(category.display_name, category.color);
                }}
              />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MCPDropdown.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  categories: PropTypes.shape({}).isRequired
};
