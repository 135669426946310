import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import get from 'lodash/get';

// eslint-disable-next-line react/prefer-stateless-function
export default class DropdownItem extends Component {
  render() {
    const { category, color, onClick } = this.props;
    // console.log("onclick",onClick);

    return (
      <div
        className="mcp-dropdown__list__item"
        onClick={() => {
          onClick();
        }}
      >
        <div
          className="mcp-dropdown__list__item__color"
          style={{ backgroundColor: color }}
        />
        <div className="mcp-dropdown__list__item__category">{category}</div>
      </div>
    );
  }
}

DropdownItem.propTypes = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  category: PropTypes.shape({}).isRequired
};
