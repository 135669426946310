import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import MCPCard from '../mcp_card/mcp_card';
import { Waypoint } from 'react-waypoint';

// eslint-disable-next-line react/prefer-stateless-function
export default class MCPCatGrid extends Component {
  render() {
    const { title, updateColor, entries, color } = this.props;

    return (
      <React.Fragment>
        {title !== '' ? (
          <React.Fragment>
            <p className="mcp__entries__title">{`FOR ${title}`}</p>
            <Waypoint
              onEnter={() => {
                updateColor(color);
              }}
              topOffset="-50%"
            />
          </React.Fragment>
        ) : null}
        <div className="mcp__entries">
          {entries &&
            entries.map((entry, idx) => (
              <MCPCard
                image={get(entry, 'image', '')}
                name={get(entry, 'name', '')}
                company={get(entry, 'company', '')}
                url={get(entry, 'url', '')}
                key={`mcp-${idx}`}
              />
            ))}
        </div>
      </React.Fragment>
    );
  }
}

MCPCatGrid.defaultProps = {
  title: '',
  color: '',
  updateColor: () => {}
};

MCPCatGrid.propTypes = {
  title: PropTypes.string,
  entries: PropTypes.shape([]).isRequired,
  updateColor: PropTypes.func,
  color: PropTypes.string
};
